/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The Type of Identifier used to fetch a single resource. Default is ID.
 */
export enum AboutIdType {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  SLUG = "SLUG",
  URI = "URI",
}

/**
 * The Type of Identifier used to fetch a single resource. Default is ID.
 */
export enum ConsultantIdType {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  SLUG = "SLUG",
  URI = "URI",
}

/**
 * The Type of Identifier used to fetch a single Form. Default is ID.
 */
export enum FormIdTypeEnum {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
}

/**
 * The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field.
 */
export enum MenuNodeIdTypeEnum {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  NAME = "NAME",
}

/**
 * The cardinality of the connection order
 */
export enum OrderEnum {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * The Type of Identifier used to fetch a single resource. Default is ID.
 */
export enum PageIdType {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  URI = "URI",
}

/**
 * The Type of Identifier used to fetch a single resource. Default is ID.
 */
export enum PositionIdType {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  SLUG = "SLUG",
  URI = "URI",
}

/**
 * The Type of Identifier used to fetch a single resource. Default is ID.
 */
export enum PostIdType {
  DATABASE_ID = "DATABASE_ID",
  ID = "ID",
  SLUG = "SLUG",
  URI = "URI",
}

/**
 * Field to order the connection by
 */
export enum PostObjectsConnectionOrderbyEnum {
  AUTHOR = "AUTHOR",
  COMMENT_COUNT = "COMMENT_COUNT",
  DATE = "DATE",
  IN = "IN",
  MENU_ORDER = "MENU_ORDER",
  MODIFIED = "MODIFIED",
  NAME_IN = "NAME_IN",
  PARENT = "PARENT",
  SLUG = "SLUG",
  TITLE = "TITLE",
}

/**
 * Submitted field data
 */
export interface InputField {
  id?: number | null;
  value?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import cn from 'classnames';

const WcdIcon = ({ ...props }) => {
	return (
		<>
			<svg
				width='100'
				height='118'
				viewBox='0 0 100 118'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={cn(' ')}
				{...props}
			>
				<g opacity='0.17'>
					<g opacity='0.17'>
						<g opacity='0.17'>
							<path
								opacity='0.17'
								d='M49.3108 116.528C32.828 116.528 6.21875 113.838 6.21875 109.838C6.21875 106.436 24.874 105.585 34.805 107.114C40.5291 107.999 44.874 108.804 49.3108 108.804'
								fill='#102a43'
							/>
						</g>
						<g opacity='0.17'>
							<path
								opacity='0.17'
								d='M49.3105 116.528C65.7933 116.528 92.4025 113.838 92.4025 109.838C92.4025 106.436 73.7473 105.585 63.8163 107.114C58.0922 107.999 53.7473 108.804 49.3105 108.804'
								fill='#102a43'
							/>
						</g>
					</g>
				</g>
				<path
					d='M91.563 78.8046V58.3103H88.5515V53.2874H87.9078V51.6092L86.2181 50.4023H82.8158H79.402L77.7124 51.6092V53.2874H77.0687V58.3103H74.0572V66.3563C73.54 66.1149 73.0227 65.8736 72.5055 65.6437L72.9078 55.4138L72.3791 51.7816L72.9078 48.1954L72.3791 44.5632L72.9078 40.3563L72.3791 36L72.9078 30.2414L72.3791 23.0345H68.563L67.9078 30.2414L68.448 36H66.1951L66.7469 40.3563L66.1951 44.5632H63.3331L62.9423 48.1954L63.3331 51.7816L62.5974 55.4138H59.8848L58.9768 61.3104C58.6319 61.2414 58.2756 61.1724 57.9308 61.1149V49.954H56.1951V28.5057H53.8848V13.9655H51.9078L51.7814 7.25287H51.6664L51.5285 2.10345H51.2066L51.0687 7.25287H50.9423L50.8158 13.9655H48.2181L48.0802 6.81609H47.9653L47.8158 0H47.494L47.3446 6.81609H47.2296L47.0917 13.9655H45.1722V30.1149V49.9655H41.7699V61.3678C41.4135 61.4368 41.0572 61.5172 40.7009 61.5977L39.1032 33.8966H37.2066L37.0917 30.3333H36.9653L36.8273 22.092H36.494L36.3561 30.3333H36.2296L36.1147 33.8966H33.2986L33.1951 30.6552H33.0687L32.9308 22.4138H32.6089L32.471 30.6552H32.3446L32.2411 33.8966H30.2181L28.402 65.6897C28.1147 65.8161 27.8273 65.9425 27.54 66.0805V51.6897L19.6319 44.1035V51.6897H19.1377H18.6434V44.1035L10.7354 51.6897V77.4713C5.13767 83.0805 1.43652 89.8046 1.43652 97.2184C1.43652 115.31 22.6894 119.839 33.9997 111.69C40.517 107 45.471 102.701 50.517 102.701C55.563 102.701 60.517 107 67.0342 111.69C78.3446 119.828 99.5974 115.31 99.5974 97.2184C99.5745 90.3793 96.4365 84.1379 91.563 78.8046Z'
					fill='#f1fcf7'
				/>
				<path
					opacity='0.31'
					d='M97.0116 88.3216C97.0116 88.3216 97.7587 92.7698 96.8507 95.0112C95.9426 97.2526 94.9311 98.0687 92.7242 97.471C90.5173 96.8733 89.6093 93.5744 81.7012 88.8733C73.7817 84.1721 62.1035 78.8848 49.4024 78.8848C36.7012 78.8848 25.0116 84.1721 17.1035 88.8733C9.18403 93.5744 8.28748 96.8733 6.08058 97.471C3.87368 98.0687 2.86219 97.2526 1.95414 95.0112C1.0461 92.7698 1.79322 88.3216 1.79322 88.3216C-2.21827 103.345 4.2185 106.597 7.26448 109.253C10.3105 111.908 17.9541 113.643 27.2875 106.471C36.6208 99.2986 49.4139 99.6894 49.4139 99.6894C49.4139 99.6894 62.207 99.3101 71.5403 106.471C80.8737 113.632 88.5173 111.908 91.5633 109.253C94.6093 106.597 101.035 103.345 97.0116 88.3216Z'
					fill='#414442'
				/>
				<path
					opacity='0.42'
					d='M83.9084 87.5975C72.7935 78.3217 62.5751 74.2987 49.2647 74.4826C35.9544 74.2987 25.7245 78.3217 14.6095 87.5975C5.26471 95.4136 1.49459 103.885 10.0923 108.862C14.1957 111.241 22.9429 108.172 29.3682 103.483C35.7475 98.8274 38.5865 98.0573 49.2647 98.0573C59.9429 98.0573 62.782 98.8274 69.1613 103.483C75.5865 108.172 84.3222 111.241 88.4371 108.862C97.0348 103.885 93.2647 95.4136 83.9084 87.5975Z'
					fill='#a4a4a4'
				/>
				<path
					d='M32.7473 75.8398L38.5749 101.196L30.6783 105.507L21.8622 93.0353L27.5404 106.725L19.1611 111.702L3.75879 97.1042L8.77028 90.8743L18.5519 99.3686L13.368 87.5065L20.3795 82.1502L29.0001 92.5755L24.8162 79.9088L32.7473 75.8398Z'
					fill='#414442'
				/>
				<path
					d='M63.9084 84.9305L54.1153 84.9419C54.1153 84.9419 54.3567 80.2408 50.1038 80.2408C50.1038 80.2408 46.1613 79.4937 46.1613 84.4937V88.2868C46.1613 93.2753 50.1498 92.9649 50.1498 92.9649C53.8279 92.9649 54.2417 90.5167 54.2417 87.873C54.3567 87.7695 63.9084 87.873 63.9084 87.873C63.9084 94.7925 61.7245 100.724 50.3452 100.724C38.0348 100.724 37.2188 93.2753 37.2188 88.8615V82.2408C37.2188 74.6891 41.6785 71.7695 49.0923 71.7695C60.6785 71.7695 63.9084 76.1833 63.9084 84.9305Z'
					fill='#414442'
				/>
				<path
					d='M70.4362 77.0234L81.1144 81.5407C83.7696 83.0119 86.965 85.0579 88.4592 89.989C89.2983 92.7361 90.1374 98.2418 87.4707 103.081C84.5397 108.391 78.896 109.725 73.7121 107.495L61.0225 102.598L70.4362 77.0234ZM75.3788 89.0005L71.8615 97.4602C71.8615 97.4602 78.0225 102.713 80.919 97.3798C84.2638 91.2188 76.2523 89.3453 75.3788 89.0005Z'
					fill='#414442'
				/>
				<path
					d='M20.1379 27.9645C20.1609 27.8955 20.1724 27.8725 20.1839 27.8496C20.4368 26.9185 20.6897 25.976 20.9425 25.045C21.1724 24.1714 21.4138 23.2978 21.6437 22.4358C21.6667 22.3553 21.6897 22.3323 21.7816 22.3553C22.0345 22.4243 22.2989 22.4932 22.5517 22.5622C23.931 22.93 25.3103 23.2978 26.6897 23.6656C26.8851 23.7231 27.0805 23.7691 27.2874 23.8151C27.2989 23.8036 27.2989 23.7921 27.3103 23.7806C25.9425 22.4128 24.5862 21.0565 23.1954 19.6656C24.5747 18.2863 25.9425 16.93 27.3218 15.5507C27.2644 15.5622 27.2299 15.5737 27.1954 15.5737C26.1954 15.8381 25.1954 16.1139 24.1839 16.3783C23.3908 16.5852 22.6092 16.8036 21.8161 17.022C21.7126 17.045 21.6782 17.022 21.6437 16.9185C21.5287 16.4702 21.4023 16.022 21.2874 15.5737C20.931 14.2519 20.5747 12.9415 20.2184 11.6197C20.2069 11.5622 20.1839 11.5047 20.1724 11.4473C20.1379 11.4932 20.1149 11.5392 20.1034 11.5852C19.931 12.2404 19.7586 12.884 19.5747 13.5392C19.2759 14.6542 18.977 15.7691 18.6782 16.884C18.6322 17.0564 18.6322 17.0565 18.4598 17.0105C17.8276 16.8381 17.1839 16.6656 16.5402 16.4932C15.4253 16.1944 14.3103 15.8955 13.2069 15.5967C13.1494 15.5852 13.092 15.5737 13 15.5622C13.046 15.6197 13.069 15.6427 13.092 15.6656C13.4023 15.976 13.7126 16.2863 14.023 16.6082C15.023 17.6082 16.0345 18.6197 17.0345 19.6197C17.1034 19.6886 17.092 19.7231 17.0345 19.7806C16.9195 19.884 16.8161 19.999 16.7126 20.1024C15.5057 21.3093 14.3103 22.5047 13.1034 23.7116C13.069 23.7461 13.046 23.7806 13 23.8266C13.069 23.8151 13.1034 23.8151 13.1379 23.8036C14.023 23.5737 14.908 23.3323 15.7931 23.0909C16.6897 22.8496 17.5747 22.6082 18.4713 22.3668C18.6552 22.3208 18.6552 22.3208 18.7011 22.5047C18.7816 22.8266 18.8736 23.1369 18.954 23.4588C19.2874 24.7001 19.6207 25.9415 19.954 27.1829C19.9885 27.4358 20.0575 27.6886 20.1379 27.9645Z'
					fill='#ED2124'
					className='animate-pulse transform'
				/>
				<path
					opacity='0.07'
					d='M88.3794 90.3339C72.092 76.7592 59.5978 74.242 49.9656 74.4489C40.3449 74.242 27.8391 76.7707 11.5632 90.3339C3.50577 97.0466 1.18394 101.69 10.5518 100.955C15.023 100.61 23.5287 96.6098 29.4827 92.7017C35.3908 88.8167 39.8851 84.0351 49.9656 84.242C60.0459 84.0236 64.5403 88.8167 70.4484 92.7017C76.4024 96.6098 84.908 100.61 89.3793 100.955C98.7471 101.679 96.4254 97.0466 88.3794 90.3339Z'
					fill='#414442'
				/>
				<path
					opacity='0.19'
					d='M88.2066 76.5974C71.7813 61.6089 55.3446 59.8848 49.3216 59.8848C33.0457 59.8848 15.6779 70.7928 10.2067 76.2526C-2.0807 88.5514 18.0802 69.8043 38.471 66.6664C44.356 65.7583 49.3216 65.7583 49.3216 65.7583C49.3216 65.7583 54.2986 65.7583 60.1721 66.6664C80.5629 69.8043 101.046 88.3215 88.2066 76.5974Z'
					fill='#414442'
				/>
				<g opacity='0.29'>
					<g opacity='0.29'>
						<path
							opacity='0.29'
							d='M20.3332 78.2301C20.3562 78.1612 20.3677 78.1382 20.3792 78.1152C20.6321 77.1842 20.885 76.2416 21.1378 75.3106C21.3677 74.437 21.6091 73.5635 21.839 72.7014C21.862 72.6209 21.885 72.5979 21.9769 72.6209C22.2298 72.6899 22.4942 72.7589 22.747 72.8278C24.1263 73.1956 25.5057 73.5635 26.885 73.9313C27.0804 73.9888 27.2758 74.0347 27.4827 74.0807C27.4942 74.0692 27.4942 74.0577 27.5057 74.0462C26.1378 72.6784 24.7815 71.3221 23.3907 69.9313C24.77 68.552 26.1378 67.1956 27.5172 65.8163C27.4597 65.8278 27.4252 65.8393 27.3907 65.8393C26.3907 66.1037 25.3907 66.3796 24.3792 66.6439C23.5861 66.8508 22.8045 67.0692 22.0114 67.2876C21.908 67.3106 21.8735 67.2876 21.839 67.1842C21.724 66.7359 21.5976 66.2876 21.4827 65.8393C21.1263 64.5175 20.77 63.2071 20.4137 61.8853C20.4022 61.8278 20.3792 61.7704 20.3677 61.7129C20.3332 61.7589 20.3103 61.8048 20.2988 61.8508C20.1263 62.506 19.9539 63.1497 19.77 63.8048C19.4712 64.9198 19.1723 66.0347 18.8735 67.1497C18.8275 67.3221 18.8275 67.3221 18.6551 67.2761C18.0229 67.1037 17.3792 66.9313 16.7355 66.7589C15.6206 66.46 14.5057 66.1612 13.4022 65.8623C13.3447 65.8508 13.2873 65.8393 13.1953 65.8278C13.2413 65.8853 13.2643 65.9083 13.2873 65.9313C13.5976 66.2416 13.908 66.552 14.2183 66.8738C15.2183 67.8738 16.2298 68.8853 17.2298 69.8853C17.2988 69.9543 17.2873 69.9888 17.2298 70.0462C17.1149 70.1497 17.0114 70.2646 16.908 70.3681C15.7011 71.575 14.5057 72.7704 13.2988 73.9773C13.2643 74.0117 13.2413 74.0462 13.1953 74.0922C13.2643 74.0807 13.2988 74.0807 13.3332 74.0692C14.2183 73.8393 15.1034 73.5979 15.9884 73.3566C16.885 73.1152 17.77 72.8738 18.6666 72.6324C18.8505 72.5865 18.8505 72.5864 18.8965 72.7704C18.9769 73.0922 19.0689 73.4025 19.1493 73.7244C19.4827 74.9658 19.816 76.2071 20.1493 77.4485C20.1838 77.7014 20.2528 77.9428 20.3332 78.2301Z'
							fill='#ED2124'
						/>
					</g>
				</g>
			</svg>
		</>
	);
};

export default WcdIcon;

const LinkedInBanner = ({ ...props }) => {
	return (
		<svg
			width='125'
			height='33.1'
			viewBox='0 0 2500 662'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g clipPath='url(#clip0)'>
				<path
					d='M0.341309 554.523H274.872V464.385H99.709V120.526H0.341309V554.523ZM413.866 554.523V255.595H314.507V554.523H413.866ZM364.195 214.789C398.837 214.789 420.403 191.833 420.403 163.13C419.761 133.801 398.837 111.497 364.855 111.497C330.864 111.497 308.637 133.81 308.637 163.13C308.637 191.833 330.204 214.789 363.544 214.789H364.195V214.789ZM459.994 554.523H559.353V387.582C559.353 378.648 559.996 369.714 562.626 363.35C569.798 345.499 586.146 327.006 613.599 327.006C649.561 327.006 663.947 354.425 663.947 394.588V554.514H763.289V383.119C763.289 291.296 714.269 248.571 648.893 248.571C595.297 248.571 571.76 278.533 558.685 298.945H559.353V255.595H459.994C461.305 283.647 459.994 554.523 459.994 554.523V554.523ZM903.463 120.526H804.104V554.523H903.463V457.604L928.294 426.339L1006.09 554.523H1128.31L997.596 368.959L1111.97 242.719H992.369C992.369 242.719 910.661 355.657 903.463 369.063V120.526Z'
					fill='currentColor'
				/>
				<path
					d='M1394.02 429.829C1395.33 422.154 1397.29 407.429 1397.29 390.777C1397.29 313.505 1358.06 235.053 1254.79 235.053C1144.32 235.053 1093.33 322.422 1093.33 401.664C1093.33 499.669 1155.43 560.87 1263.93 560.87C1307.08 560.87 1346.94 554.514 1379.64 541.118L1366.56 475.498C1339.75 484.388 1312.3 488.842 1278.32 488.842C1231.91 488.842 1191.39 469.785 1188.12 429.187L1394.02 429.812V429.829V429.829ZM1187.47 362.612C1190.08 337.104 1207.09 299.466 1249.57 299.466C1294.67 299.466 1305.14 339.647 1305.14 362.612H1187.48H1187.47ZM1642.53 120.526V270.763H1641.22C1626.84 249.735 1596.77 235.704 1556.92 235.704C1480.43 235.704 1413.11 296.914 1413.75 401.482C1413.75 498.41 1474.55 561.538 1550.36 561.538C1591.55 561.538 1630.76 543.67 1650.38 509.237H1652.34L1656.27 554.523H1744.51C1743.21 533.477 1741.89 497.134 1741.89 461.424V120.518H1642.53L1642.53 120.526ZM1642.53 416.155C1642.53 423.795 1641.88 431.462 1640.56 437.817C1634.7 465.253 1611.17 484.362 1582.4 484.362C1541.23 484.362 1514.42 451.214 1514.42 398.929C1514.42 349.84 1537.32 310.302 1583.06 310.302C1613.78 310.302 1635.35 331.339 1641.23 357.472C1642.54 363.228 1642.53 369.61 1642.53 375.34V416.146V416.155Z'
					fill='currentColor'
				/>
				<path
					d='M2041.22 554.158V255.23H1941.86V554.158H2041.23H2041.22ZM1991.56 214.424C2026.2 214.424 2047.77 191.468 2047.77 162.782C2047.12 133.445 2026.2 111.132 1992.22 111.132C1958.21 111.132 1936 133.445 1936 162.782C1936 191.468 1957.56 214.424 1990.91 214.424H1991.55H1991.56ZM2096.21 554.158H2195.56V387.243C2195.56 378.318 2196.22 369.375 2198.84 363.003C2206.02 345.143 2222.37 326.659 2249.82 326.659C2285.76 326.659 2300.15 354.069 2300.15 394.258V554.158H2399.5V382.763C2399.5 290.949 2350.49 248.224 2285.12 248.224C2231.53 248.224 2207.99 278.178 2194.91 298.581H2195.57V255.239H2096.22C2097.52 283.283 2096.21 554.167 2096.21 554.167V554.158Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='2499.64' height='662' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LinkedInBanner;
